<template>
    <v-dialog persistent width="40vw" :fullscreen="acceptLiability" v-model="shipmentProfileDialog">
        <v-card>
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Edit Shipment Profile Details
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="acceptLiability" @click="updateShipment()" :loading="savingShipment" text color="secondary"
                    class="mr-2" :disabled="!canSaveShipment"><v-icon>save</v-icon></v-btn>
                <v-btn v-if="acceptLiability" text @click="closeShipmentProfileModal()"
                    color="primary"><v-icon>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text v-if="!acceptLiability">
                <v-card-text>
                    <p>
                        By clicking the "Accept" button below, you acknowledge and agree that any alterations made to
                        the
                        Shipment
                        Profile are your sole responsibility.
                        Any resultant changes to documentation due to these modifications must be substantiated with
                        pertinent
                        supporting materials.
                        Failing to provide such documentation may lead to criminal liability and/or termination of your
                        employment.
                        Please be aware that all amendments to the Shipment Profile will be meticulously tracked and
                        attributed to
                        the individual making the changes.
                        The system holds no liability for any charges or implications arising from these alterations.
                    </p>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-btn class="mx-2" @click="closeShipmentProfileModal()">Cancel</v-btn>
                        <v-btn class="mx-2" color="primary" @click="acceptLiability = true">Accept</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card-text>
            <v-card-text v-else>
                <!-- SHIPMENT DETAILS -->
                <v-row>
                    <v-col class="pb-0">
                        <v-card flat tile>
                            <v-toolbar flat>
                                <v-card-title>Shipment Details</v-card-title>
                            </v-toolbar>
                            <v-card-text v-if="shipment && shipment.id">
                                <v-row>
                                    <v-col class="pb-0">
                                        <v-select dense outlined clearable label="Inco Term"
                                            v-model="shipment.shipmentIncoTerm" :items="incoTerms"
                                            item-text="abbreviation" item-value="abbreviation">
                                            <template v-slot:item="data">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ data.item.abbreviation
                                                        }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                        <v-select clearable outlined dense label="Origin Charges*"
                                            :items="filteredCharges" v-model="shipment.originCharge"
                                            item-text="friendlyName" item-value="value"
                                            style="text-transform: capitalize" @change="removeInvoiceParty('origin')">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                        <v-select clearable outlined dense label="Freight Charges*"
                                            :items="filteredCharges" item-text="friendlyName" item-value="value"
                                            v-model="shipment.freightCharge" style="text-transform: capitalize"
                                            @change="removeInvoiceParty('freight')">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                        <v-select clearable outlined dense label="Destination Charges*"
                                            :items="filteredCharges" v-model="shipment.destinationCharge"
                                            style="text-transform: capitalize" item-text="friendlyName"
                                            item-value="value" @change="removeInvoiceParty('destination')">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pb-0">
                                        <v-select clearable outlined dense label="Contract Owner*"
                                            :items="filteredCharges" v-model="shipment.incoTermContractOwner"
                                            item-text="friendlyName" item-value="value"
                                            style="text-transform: capitalize">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                        <v-select clearable outlined dense label="Forwarder Invoice Origin Charge To*"
                                            :items="filteredCharges" :disabled="shipment.originCharge !== 'forwarder'"
                                            v-model="shipment.originInvoiceTo" item-text="friendlyName"
                                            item-value="value" style="text-transform: capitalize" ref="originInvoice">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                        <v-select clearable outlined dense label="Forwarder Invoice Freight Charge To*"
                                            :items="filteredCharges" :disabled="shipment.freightCharge !== 'forwarder'"
                                            v-model="shipment.freightInvoiceTo" item-text="friendlyName"
                                            item-value="value" style="text-transform: capitalize" ref="freightInvoice">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                        <v-select clearable outlined dense
                                            label="Forwarder Invoice Destination Charge To*" :items="filteredCharges"
                                            :disabled="shipment.destinationCharge !== 'forwarder'"
                                            v-model="shipment.destinationInvoiceTo" item-text="friendlyName"
                                            item-value="value" style="text-transform: capitalize"
                                            ref="destinationInvoice">
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.friendlyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-container>
                                <v-card v-if="relationships.length > 0" tile outlined :loading=removingCustomer>
                                    <v-row align="center" class="mt-1" v-for="relationship in relationships"
                                        :key="relationship.id">
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-icon class="mt-0 pt-0">groups</v-icon>
                                            <br />
                                            <span style="font-size: 10px; font-weight: bold">CUSTOMER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{ 'font-size': '14px', 'padding-bottom': '0px' }"
                                                class="my-0 pt-0"><span>{{ relationship?.customer?.name }}</span>
                                            </v-card-title>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0 pr-5">
                                            <v-card-text class="d-flex align-center">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small text plain v-on="on"
                                                                @click="openCustomerModal(relationship)"> 
                                                                <v-icon color="green">swap_horiz</v-icon> 
                                                            </v-btn>
                                                        </template>
                                                        <span>Change Customer</span>
                                                    </v-tooltip>
                                                <div v-if="relationships.length > 1">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small text plain v-on="on"
                                                                @click="removeCustomer(relationship)"> 
                                                                <v-icon color="red"> delete </v-icon> 
                                                            </v-btn>
                                                        </template>
                                                        <span>Remove Customer</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card v-else tile outlined :loading=removingCustomer>
                                    <v-row align="center" class="mt-1" >
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-icon color="danger" class="mt-0 pt-0">groups</v-icon>
                                            <br />
                                            <span style="font-size: 10px; font-weight: bold">CUSTOMER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{ 'font-size': '14px', 'padding-bottom': '0px' }"
                                                class="my-0 pt-0"><span>No Customer Selected</span>
                                            </v-card-title>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0 pr-5">
                                            <v-card-text class="d-flex align-center">
                                                <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn small text plain v-on="on"
                                                                @click="openCustomerModal(relationship)"> <v-icon
                                                                    color="green">
                                                                    add
                                                                </v-icon> </v-btn>
                                                        </template>
                                                        <span>Add Customer</span>
                                                    </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-container>

                            <v-divider></v-divider>
                            <v-subheader class="mt-0 pt-0 primaryText--text" style="font-size: 16px">
                                <v-icon left color="grey">groups</v-icon> Assigned Parties
                                <v-spacer></v-spacer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" text plain icon
                                            @click="partyModal = true"><v-icon>add_circle_outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add Party</span>
                                </v-tooltip>

                                <!-- <v-checkbox disabled v-model="shipment.letterOfCredit"
                                    label="Letter of Credit Required"></v-checkbox> -->
                            </v-subheader>

                            <v-list dense subheader :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'">

                                <!-- Bank -->
                                <v-card tile outlined v-if="shipment.bank">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-icon class="mt-0 pt-0">account_balance</v-icon>
                                            <br />
                                            <span style="font-size: 10px; font-weight: bold">BANK</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{ 'font-size': '14px', 'padding-bottom': '0px' }"
                                                class="my-0 pt-0"><span v-if="shipment.bank">{{ shipment.bank.name
                                                    }}</span>
                                                <span v-else>-</span>
                                            </v-card-title>
                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.bankAddressId ||
                                                    shipment.bankAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.bankAddressDescription">{{
                                                        shipment.bankAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.bankAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.bank)" v-on="on"
                                                                class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'bank')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.bank, 'bank')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Shipper -->
                                <v-card tile outlined v-if="shipment.shipper">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon class="mt-0 pt-0">directions_boat_filled</v-icon>
                                            <br />
                                            <span style="font-size: 10px; font-weight: bold">SHIPPER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipment.onBehalfShipper ? '0px' : '6px',
                                            }" class="my-0 pt-0">{{ shipment.shipper.name }}
                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipment.shipper && shipment.onBehalfShipper">
                                                On Behalf of {{ shipment.onBehalfShipper.name }}
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.onBehalfShipper, 'onBehalfShipper')">
                                                            <v-icon small color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipment.shipper && !shipment.onBehalfShipper">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.shipperAddressId ||
                                                    shipment.shipperAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.shipperAddressDescription">{{
                                                        shipment.shipperAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.shipperAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.shipper)" v-on="on"
                                                                class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'shipper')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipment.onBehalfShipper && shipment.onBehalfShipperAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipment.onBehalfShipperAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.onBehalfShipperAddress)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'onBehalfShipper')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.shipper, 'shipper')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- forwarder -->
                                <v-card tile outlined v-if="shipment.forwarder">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>fast_forward</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">FORWARDER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipment.onBehalfForwarder
                                                    ? '0px'
                                                    : '5px',
                                            }" class="my-0 pt-0">{{ shipment.forwarder.name }}
                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipment.forwarder && shipment.onBehalfForwarder">
                                                On Behalf of {{ shipment.onBehalfForwarder.name }}
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.onBehalfForwarder, 'onBehalfForwarder')">
                                                            <v-icon small color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipment.forwarder && !shipment.onBehalfForwarder">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.forwarderAddressId ||
                                                    shipment.forwarderAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.forwarderAddressDescription">{{
                                                        shipment.forwarderAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.forwarderAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.forwarder)" v-on="on"
                                                                class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'forwarder')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipment.onBehalfForwarder && shipment.onBehalfForwarderAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipment.onBehalfForwarderAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.onBehalfForwarder)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'onBehalfForwarder')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.forwarder, 'forwarder')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- consignee -->
                                <v-card tile outlined v-if="shipment.consignee">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>call_received</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">CONSIGNEE</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipment.onBehalfConsignee && !(shipment.letterOfCredit && shipment.bank)
                                                    ? '0px'
                                                    : '5px',
                                            }" class="my-0 pt-0">{{ shipment.consignee.name }}
                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipment.consignee && shipment.onBehalfConsignee">
                                                On Behalf of {{ shipment.onBehalfConsignee.name }}
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.onBehalfConsignee, 'onBehalfConsignee')">
                                                            <v-icon small color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-subtitle>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipment.letterOfCredit && shipment.bank">
                                                To the Order of {{ shipment.bank.name }}</v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipment.consignee && !shipment.onBehalfConsignee">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.consigneeAddressId ||
                                                    shipment.consigneeAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.consigneeAddressDescription">{{
                                                        shipment.consigneeAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.consigneeAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.consignee)" v-on="on"
                                                                class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'consignee')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipment.onBehalfConsignee && shipment.onBehalfConsigneeAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipment.onBehalfConsigneeAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.onBehalfConsignee)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'onBehalfConsignee')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.consignee, 'consignee')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Buyer -->
                                <v-card tile outlined v-if="shipment.buyer">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>shopping_cart</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">BUYER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipment.onBehalfBuyer
                                                    ? '0px'
                                                    : '5px',
                                            }" class="my-0 pt-0">{{ shipment.buyer.name }}

                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipment.buyer && shipment.onBehalfBuyer">
                                                On Behalf of {{ shipment.onBehalfBuyer.name }}
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.onBehalfBuyer, 'onBehalfBuyer')">
                                                            <v-icon small color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipment.buyer && !shipment.onBehalfBuyer">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.buyerAddressId ||
                                                    shipment.buyerAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.buyerAddressDescription">{{
                                                        shipment.buyerAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.buyerAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.buyer)" v-on="on"
                                                                class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'buyer')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipment.onBehalfBuyer && shipment.onBehalfBuyerAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipment.onBehalfBuyerAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.onBehalfBuyer)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'onBehalfBuyer')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.buyer, 'buyer')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- First Notify -->
                                <v-card tile outlined v-if="shipment.firstNotify">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>notifications</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">1st NOTIFY</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipment.firstNotify.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.firstNotifyAddressId ||
                                                    shipment.firstNotifyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.firstNotifyAddressDescription">{{
                                                        shipment.firstNotifyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.firstNotifyAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.firstNotify)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'firstNotify')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.firstNotify, 'firstNotify')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Second Notify -->
                                <v-card tile outlined v-if="shipment.secondNotify">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>notifications</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">2nd NOTIFY</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipment.secondNotify.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.secondNotifyAddressId ||
                                                    shipment.secondNotifyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.secondNotifyAddressDescription">{{
                                                        shipment.secondNotifyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.secondNotifyAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.secondNotify)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'secondNotify')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.secondNotify, 'secondNotify')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Courier Party -->
                                <v-card tile outlined v-if="shipment.courierParty">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>local_post_office</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">COURIER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipment.courierParty.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.courierPartyAddressId ||
                                                    shipment.courierPartyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.courierPartyAddressDescription">{{
                                                        shipment.courierPartyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.courierPartyAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.courierParty)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'courierParty')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.courierParty, 'courierParty')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <!-- Additional Courier Party -->
                                <v-card tile outlined v-if="shipment.additionalCourierParty">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>local_post_office</v-icon> <br />
                                            <span class="my-0" style="font-size: 10px; font-weight: bold">ADD.
                                                COURIER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipment.additionalCourierParty.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipment.courierPartyAddressId ||
                                                    shipment.courierPartyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipment.courierPartyAddressDescription">{{
                                                        shipment.courierPartyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipment.courierPartyAddress)
                                                        }}</span>
                                                    <v-menu transition="scale-transition" v-if="!loadingProfile">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="edit" text plain icon small v-bind="attrs"
                                                                @click="getPartyAddresses(shipment.additionalCourierParty)"
                                                                v-on="on" class="mx-1">
                                                                <v-icon small>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card :loading="loadingAdresses">
                                                            <v-list :disabled="loadingAdresses">
                                                                <v-list-item v-for="address in shipmentPartyAddresses"
                                                                    :key="address.id"
                                                                    @click="selectPartyAddress(address, 'additionalCourierParty')">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ address.alias
                                                                            }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ address.description
                                                                            }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card>
                                                    </v-menu>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="my-0 py-0">
                                            <v-card-text>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn text plain v-on="on"
                                                            @click="removePartyFromShipment(shipment.additionalCourierParty, 'additionalCourierParty')">
                                                            <v-icon color="red">
                                                                delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Party</span>
                                                </v-tooltip>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-divider vertical></v-divider>

                    <!-- SHIPMENT PROFILE DETAILS -->
                    <v-col class="pb-0">
                        <v-card :loading="loadingProfile" flat tile>
                            <v-toolbar flat>
                                <v-card-title>
                                    Linked Shipment Profile
                                </v-card-title>
                                <code v-if="shipment.consigneeProfile">{{ shipmentProfile.systemReference }}</code>
                                <v-skeleton-loader v-if="loadingProfile" class="mx-4" type="chip"></v-skeleton-loader>
                                <v-chip v-else :color="getStatusColor(shipmentProfile.status)" dense pill class="mx-4">
                                    {{
                                        shipmentProfile.status }}</v-chip>
                            </v-toolbar>
                            <v-card-text v-if="shipmentProfile && shipmentProfile.id">
                                <v-row>
                                    <v-col class="pb-0">
                                        <v-list class="scroll">
                                            <v-card outlined flat tile
                                                v-for="incoTerm in shipmentProfile.consigneeProfileIncoTerms"
                                                :key="incoTerm.id">
                                                <div v-if="incoTerm && incoTerm.id">
                                                    <v-card-title>
                                                        Inco Term: {{ incoTerm.incoTerm }}
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col>
                                                                <v-card-subtitle>
                                                                    <b>Inco Term Place:</b> {{ incoTerm.incoTermPlace }}
                                                                </v-card-subtitle>
                                                                <v-card-text>
                                                                    <b>Origin Charge:</b> {{ incoTerm.originCharge }}
                                                                    <br>
                                                                    <b>Freight Charge:</b> {{ incoTerm.freightCharge }}
                                                                    <br>
                                                                    <b>Destination Charge:</b> {{
                                                                        incoTerm.destinationCharge
                                                                    }}
                                                                    <br>
                                                                </v-card-text>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card-subtitle>
                                                                    <b>Payment Term:</b> {{ incoTerm.paymentTerm }}
                                                                </v-card-subtitle>
                                                                <v-card-text>
                                                                    <b>Origin Invoice To:</b> {{
                                                                    incoTerm.originInvoiceTo }}
                                                                    <br>
                                                                    <b>Freight Invoice To:</b> {{
                                                                        incoTerm.freightInvoiceTo
                                                                    }}
                                                                    <br>
                                                                    <b>Destination Invoice To:</b> {{
                                                                        incoTerm.destinationInvoiceTo
                                                                    }} <br>
                                                                </v-card-text>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card-subtitle>
                                                                    <b>Contract Owner:</b> {{ incoTerm.contractOwner }}
                                                                </v-card-subtitle>
                                                                <v-card-text
                                                                    v-if="incoTerm.consigneeProfileShippingContracts"
                                                                    class="ma-0">
                                                                    <div v-for="contract in incoTerm.consigneeProfileShippingContracts"
                                                                        :key="contract.id" class="mb-2">.
                                                                        <div v-if="contract && contract.id">
                                                                            <v-list-item-subtitle
                                                                                v-if="contract.shippingLine && contract.shippingLine.friendlyName">Shipping
                                                                                Line: {{
                                                                                    contract.shippingLine.friendlyName
                                                                                }}</v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                v-else-if="contract.shippingLine && contract.shippingLine.name">Shipping
                                                                                Line: {{
                                                                                    contract.shippingLine.name
                                                                                }}</v-list-item-subtitle>
                                                                            <v-list-item-subtitle>Contract Number: {{
                                                                                contract.contractNumber
                                                                                }}</v-list-item-subtitle>
                                                                        </div>
                                                                    </div>
                                                                </v-card-text>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-subheader v-if="!loadingProfile" class="mt-0 pt-0 primaryText--text"
                                style="font-size: 16px">
                                <v-icon left color="grey">groups</v-icon> Assigned Parties
                                <v-spacer></v-spacer>
                                <v-checkbox disabled v-model="shipmentProfile.letterOfCredit"
                                    label="Letter of Credit Required"></v-checkbox>
                            </v-subheader>
                            <v-list :loading="loadingProfile" dense subheader
                                :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'">
                                <!-- Bank -->

                                <v-card tile outlined v-if="shipmentProfile.bank">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-icon class="mt-0 pt-0">account_balance</v-icon>
                                            <br />
                                            <span style="font-size: 10px; font-weight: bold">BANK</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{ 'font-size': '14px', 'padding-bottom': '0px' }"
                                                class="my-0 pt-0"><span v-if="shipmentProfile.bank">{{
                                                    shipmentProfile.bank.name }}</span>
                                                <span v-else>-</span>
                                            </v-card-title>
                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.bankAddressId ||
                                                    shipmentProfile.bankAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.bankAddressDescription">{{
                                                        shipmentProfile.bankAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.bankAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.bank)"
                                                        @click="addPartyToShipment(shipmentProfile.bank, 'bank')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Shipper -->
                                <v-card tile outlined v-if="shipmentProfile.shipper">
                                    <v-row align="center" justify="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon class="mt-0 pt-0">directions_boat_filled</v-icon>
                                            <br />
                                            <span style="font-size: 10px; font-weight: bold">SHIPPER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipmentProfile.onBehalfShipper ? '0px' : '6px',
                                            }" class="my-0 pt-0">{{ shipmentProfile.shipper.name }}
                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipmentProfile.shipper && shipmentProfile.onBehalfShipper">
                                                On Behalf of {{ shipmentProfile.onBehalfShipper.name
                                                }}
                                            </v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipmentProfile.shipper && !shipmentProfile.onBehalfShipper">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.shipperAddressId ||
                                                    shipmentProfile.shipperAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.shipperAddressDescription">{{
                                                        shipmentProfile.shipperAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.shipperAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipmentProfile.onBehalfShipper && shipmentProfile.onBehalfShipperAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipmentProfile.onBehalfShipperAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.shipper)"
                                                        @click="addPartyToShipment(shipmentProfile.shipper, 'shipper')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- forwarder -->
                                <v-card tile outlined v-if="shipmentProfile.forwarder">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>fast_forward</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">FORWARDER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipmentProfile.onBehalfForwarder
                                                    ? '0px'
                                                    : '5px',
                                            }" class="my-0 pt-0">{{ shipmentProfile.forwarder.name }}
                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipmentProfile.forwarder && shipmentProfile.onBehalfForwarder">
                                                On Behalf of {{ shipmentProfile.onBehalfForwarder.name
                                                }}</v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipmentProfile.forwarder && !shipmentProfile.onBehalfForwarder">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.forwarderAddressId ||
                                                    shipmentProfile.forwarderAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.forwarderAddressDescription">{{
                                                        shipmentProfile.forwarderAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.forwarderAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipmentProfile.onBehalfForwarder && shipmentProfile.onBehalfForwarderAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipmentProfile.onBehalfForwarderAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.forwarder)"
                                                        @click="addPartyToShipment(shipmentProfile.forwarder, 'forwarder')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- consignee -->
                                <v-card tile outlined v-if="shipmentProfile.consignee">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>call_received</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">CONSIGNEE</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipmentProfile.onBehalfConsignee && !(shipmentProfile.letterOfCredit && shipmentProfile.bank)
                                                    ? '0px'
                                                    : '5px',
                                            }" class="my-0 pt-0">{{ shipmentProfile.consignee.name }}
                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipmentProfile.consignee && shipmentProfile.onBehalfConsignee">
                                                On Behalf of {{ shipmentProfile.onBehalfConsignee.name
                                                }}</v-card-subtitle>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipmentProfile.letterOfCredit && shipmentProfile.bank">
                                                To the Order of {{ shipmentProfile.bank.name }}</v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipmentProfile.consignee && !shipmentProfile.onBehalfConsignee">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.consigneeAddressId ||
                                                    shipmentProfile.consigneeAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.consigneeAddressDescription">{{
                                                        shipmentProfile.consigneeAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.consigneeAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipmentProfile.onBehalfConsignee && shipmentProfile.onBehalfConsigneeAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipmentProfile.onBehalfConsigneeAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.consignee)"
                                                        @click="addPartyToShipment(shipmentProfile.consignee, 'consignee')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Buyer -->
                                <v-card tile outlined v-if="shipmentProfile.buyer">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>shopping_cart</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">BUYER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': !shipmentProfile.onBehalfBuyer
                                                    ? '0px'
                                                    : '5px',
                                            }" class="my-0 pt-0">{{ shipmentProfile.buyer.name }}

                                            </v-card-title>
                                            <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                                                v-if="shipmentProfile.buyer && shipmentProfile.onBehalfBuyer">
                                                On Behalf of {{ shipmentProfile.onBehalfBuyer.name }}</v-card-subtitle>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-if="shipmentProfile.buyer && !shipmentProfile.onBehalfBuyer">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.buyerAddressId ||
                                                    shipmentProfile.buyerAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.buyerAddressDescription">{{
                                                        shipmentProfile.buyerAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.buyerAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="my-0 mt-0 pt-0"
                                                v-else-if="shipmentProfile.onBehalfBuyer && shipmentProfile.onBehalfBuyerAddress">
                                                <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span>{{
                                                        concatenateAddress(shipmentProfile.onBehalfBuyerAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.buyer)"
                                                        @click="addPartyToShipment(shipmentProfile.buyer, 'buyer')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- First Notify -->
                                <v-card tile outlined v-if="shipmentProfile.firstNotify">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>notifications</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">1st NOTIFY</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipmentProfile.firstNotify.name }}
                                            </v-card-title>
                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.firstNotifyAddressId ||
                                                    shipmentProfile.firstNotifyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.firstNotifyAddressDescription">{{
                                                        shipmentProfile.firstNotifyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.firstNotifyAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.firstNotify)"
                                                        @click="addPartyToShipment(shipmentProfile.firstNotify, 'firstNotify')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Second Notify -->
                                <v-card tile outlined v-if="shipmentProfile.secondNotify">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>notifications</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">2nd NOTIFY</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipmentProfile.secondNotify.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.secondNotifyAddressId ||
                                                    shipmentProfile.secondNotifyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.secondNotifyAddressDescription">{{
                                                        shipmentProfile.secondNotifyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.secondNotifyAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.secondNotify)"
                                                        @click="addPartyToShipment(shipmentProfile.secondNotify, 'secondNotify')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Courier Party -->
                                <v-card tile outlined v-if="shipmentProfile.courierParty">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>local_post_office</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">COURIER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipmentProfile.courierParty.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.courierPartyAddressId ||
                                                    shipmentProfile.courierPartyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.courierPartyAddressDescription">{{
                                                        shipmentProfile.courierPartyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.courierPartyAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.courierParty)"
                                                        @click="addPartyToShipment(shipmentProfile.courierParty, 'courierParty')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <!-- Additional Courier Party -->
                                <v-card tile outlined v-if="shipmentProfile.additionalCourierParty">
                                    <v-row align="center" class="mt-1">
                                        <v-col cols="12" sm="2" class="text-center my-0 py-0">
                                            <v-icon>local_post_office</v-icon> <br />
                                            <span style="font-size: 10px; font-weight: bold">ADD. COURIER</span>
                                        </v-col>
                                        <v-col cols="12" sm="8" class="my-0 py-0">
                                            <v-card-title :style="{
                                                'font-size': '14px',
                                                'padding-bottom': '0px'
                                            }" class="my-0 pt-0">{{ shipmentProfile.additionalCourierParty.name }}
                                            </v-card-title>

                                            <v-card-text class="my-0 mt-0 pt-0">
                                                <p style="font-size: 12px; color: grey" v-if="shipmentProfile.courierPartyAddressId ||
                                                    shipmentProfile.courierPartyAddressDescription
                                                " class="text-wrap my-0 py-0">
                                                    <v-icon class="mr-1" small>location_on</v-icon>
                                                    <span v-if="shipmentProfile.courierPartyAddressDescription">{{
                                                        shipmentProfile.courierPartyAddressDescription
                                                        }}</span>
                                                    <span v-else>{{
                                                        concatenateAddress(shipmentProfile.courierPartyAddress)
                                                        }}</span>
                                                </p>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" sm="2" class="text-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text plain class="mx-auto"
                                                        :disabled="partyExsist(shipment.additionalCourierParty)"
                                                        @click="addPartyToShipment(shipmentProfile.additionalCourierParty, 'additionalCourierParty')">
                                                        <v-icon color="success">
                                                            reply_all
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Party</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- Party search modal -->
        <v-dialog v-model="partyModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card :loading="loadingLinkedOrganisations">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Add Party
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="partyModal = false, searchOrganisation = null">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-select v-model="partyType" outlined dense placeholder="Select Party Type" item-text="name"
                        item-value="value" :items="partyTypes"></v-select>
                    <v-text-field v-if="partyType" outlined dense placeholder="Search" prepend-inner-icon="search"
                        v-model="searchOrganisation" clearable></v-text-field>
                    <v-list v-if="partyType" selectable dense style="max-height: 50vh; overflow-y:auto">
                        <v-list-item v-for="organisation in filterCustomerOrganisations" :key="organisation.id"
                            @click="setParty(organisation.relatedOrganisation)">
                            <v-list-item-action>
                                <v-avatar v-if="organisation.relatedOrganisation.logo" color="white">
                                    <v-img :src="organisation.relatedOrganisation.logo" contain></v-img>
                                </v-avatar>
                                <v-avatar v-else color="secondary">
                                    <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                                </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ organisation.relatedOrganisation.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>


        <!-- Customer search modal -->
        <v-dialog v-model="customerModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card :loading="loadingLinkedOrganisations">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Change Customer
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="customerModal = false, searchOrganisation = null">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field outlined dense placeholder="Search" prepend-inner-icon="search"
                        v-model="searchOrganisation" clearable></v-text-field>
                    <v-list selectable dense style="max-height: 50vh; overflow-y:auto">
                        <v-list-item v-for="relationship in filterCustomers" :key="relationship.id"
                            @click="setCustomer(relationship)">
                            <v-list-item-action>
                                <v-avatar v-if="relationship.relatedOrganisation.logo" color="white">
                                    <v-img :src="relationship.relatedOrganisation.logo" contain></v-img>
                                </v-avatar>
                                <v-avatar v-else color="secondary">
                                    <h3>{{ relationship.relatedOrganisation.name.charAt(0) }}</h3>
                                </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ relationship.relatedOrganisation.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
export default {
    props: ['shipment', 'shipmentProfileDialog', 'relationships'],
    data: () => ({
        newRelationship: {},
        customers: [],
        removingCustomer: false,
        customerModal: false,
        changeCustomerId: null,
        partyModal: false,
        partyType: null,
        partyAddress: null,
        partyAddresses: [],
        selectedOrganisation: null,
        searchOrganisation: null,
        loadingLinkedOrganisations: false,
        linkedOrganisations: [],
        shipmentProfile: {},
        acceptLiability: false,
        loadingProfile: false,
        originCharge: '',
        freightCharge: '',
        destinationCharge: '',
        savingShipment: false,
        shipmentPartyAddresses: [],
        loadingAdresses: false,
        incoTerms: [],
        chargeOptions: [
            { friendlyName: '', name: "Buyer", value: "buyer" },
            { friendlyName: '', name: "Forwarder", value: "forwarder" },
            { friendlyName: '', name: "On behalf Forwarder", value: "onBehalfForwarder" },
            { friendlyName: '', name: "Shipper", value: "shipper" },
            { friendlyName: '', name: "On behalf Shipper", value: "onBehalfShipper" },
            { friendlyName: '', name: "Consignee", value: "consignee" },
            { friendlyName: '', name: "1st Notify Party", value: "firstNotify" },
            { friendlyName: '', name: "2nd Notify Party", value: "secondNotify" },
            { friendlyName: '', name: "Customer", value: "customer" },
        ],
    }),
    watch: {
        'shipmentProfileDialog': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getShipmentProfile()
                }
            }
        },
    },
    async mounted() {
        this.setParties()
    },
    created() {
        this.getRelatedOrganisations()
        this.getIncoTerms()
        this.getShipmentPartyAdresses()
        this.setParties()
    },
    computed: {
        filteredCharges() {
            return this.chargeOptions.filter(x => x.friendlyName !== null)
        },
        filterCustomerOrganisations() {
            let result = this.linkedOrganisations ?? []
            if (this.searchOrganisation) {
                result = result.filter(organisation => organisation.relatedOrganisation.name.toLowerCase().includes(this.searchOrganisation.toLowerCase()))
            }
            result.sort((a, b) => (a.relatedOrganisation.name > b.relatedOrganisation.name) ? 1 : ((b.relatedOrganisation.name > a.relatedOrganisation.name) ? -1 : 0))
            return result
        },
        filterCustomers() {
            let result = this.customers ?? []
            if (this.searchOrganisation) {
                result = result.filter(organisation => organisation.relatedOrganisation.name.toLowerCase().includes(this.searchOrganisation.toLowerCase()))
            }
            result.sort((a, b) => (a.relatedOrganisation.name > b.relatedOrganisation.name) ? 1 : ((b.relatedOrganisation.name > a.relatedOrganisation.name) ? -1 : 0))
            return result
        },
        partyTypes() {
            let types = []
            if (!this.shipment.bankId) {
                types.push({ name: "Bank", value: "bank" })
            }
            if (!this.shipment.forwarderId) {
                types.push({ name: "Forwarder", value: "forwarder" })
            }
            if (!this.shipment.courierPartyId) {
                types.push({ name: "Courier Party", value: "courierParty" })
            }
            if (!this.shipment.additionalCourierPartyId) {
                types.push({ name: "Additional Courier Party", value: "additionalCourierParty" })
            }
            if (!this.shipment.shipperId) {
                types.push({ name: "Shipper", value: "shipper" })
            }
            if (!this.shipment.buyerId) {
                types.push({ name: "Buyer", value: "buyer" })
            }
            if (!this.shipment.consigneeId) {
                types.push({ name: "Consignee", value: "consignee" })
            }
            if (!this.shipment.firstNotifyId) {
                types.push({ name: "First Notify", value: "firstNotify" })
            }
            if (!this.shipment.secondNotifyId) {
                types.push({ name: "Second Notify", value: "secondNotify" })
            }
            if (!this.shipment.onBehalfShipperId && this.shipment.shipperId) {
                types.push({ name: "On Behalf Shipper", value: "onBehalfShipper" })
            }
            if (!this.shipment.onBehalfBuyerId && this.shipment.buyerId) {
                types.push({ name: "On Behalf Buyer", value: "onBehalfBuyer" })
            }
            if (!this.shipment.onBehalfConsigneeId && this.shipment.consigneeId) {
                types.push({ name: "On Behalf Consignee", value: "onBehalfConsignee" })
            }
            if (!this.shipment.onBehalfForwarderId && this.shipment.forwarderId) {
                types.push({ name: "On Behalf Forwarder", value: "onBehalfForwarder" })
            }
            return types;
        },
        canSaveShipment() {
            let result = false
            if (this.shipment && this.shipment.shipper && this.shipment.consignee && this.shipment.forwarder) {
                result = true
                if (!this.shipment.shipmentIncoTerm || !this.shipment.incoTermContractOwner || !this.shipment.originCharge || !this.shipment.freightCharge || !this.shipment.destinationCharge) {
                    result = false
                }
                if (this.shipment.originCharge && this.shipment.originCharge === 'forwarder' && !this.shipment.originInvoiceTo) {
                    result = false
                }
                if (this.shipment.freightCharge && this.shipment.freightCharge === 'forwarder' && !this.shipment.freightInvoiceTo) {
                    result = false
                }
                if (this.shipment.destinationCharge && this.shipment.destinationCharge === 'forwarder' && !this.shipment.destinationInvoiceTo) {
                    result = false
                }
            } else {
                result = false
            }

            return result
        },
    },
    methods: {
        async getRelatedOrganisations() {
            this.loadingLinkedOrganisations = true
            this.linkedOrganisations = await this.$API.getShipmentCustomerRelationships(this.shipment.id);
            // console.log(this.linkedOrganisations)
            this.customers = await this.$API.getCustomers()
            // console.log(this.customers)
            this.loadingLinkedOrganisations = false
        },
        async getIncoTerms() {
            this.incoTerms = await this.$API.getIncoTerms();
        },
        async getShipmentPartyAdresses() {
            if (this.shipment.bank && this.shipment.bankId) {
                this.shipment.bank.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.bankId)
            }
            if (this.shipment.shipper && this.shipment.shipperId) {
                this.shipment.shipper.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.shipperId)
            }
            if (this.shipment.forwarder && this.shipment.forwarderId) {
                this.shipment.forwarder.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.forwarderId)
            }
            if (this.shipment.consignee && this.shipment.consigneeId) {
                this.shipment.consignee.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.consigneeId)
            }
            if (this.shipment.buyer && this.shipment.buyerId) {
                this.shipment.buyer.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.buyerId)
            }
            if (this.shipment.firstNotify && this.shipment.firstNotifyId) {
                this.shipment.firstNotify.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.firstNotifyId)
            }
            if (this.shipment.secondNotify && this.shipment.secondNotifyId) {
                this.shipment.secondNotify.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.secondNotifyId)
            }
            if (this.shipment.courierParty && this.shipment.courierPartyId) {
                this.shipment.courierParty.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.courierPartyId)
            }
            if (this.shipment.additionalCourierParty && this.shipment.additionalCourierPartyId) {
                this.shipment.additionalCourierParty.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.additionalCourierPartyId)
            }
            if (this.shipment.onBehalfShipper && this.shipment.onBehalfShipperId) {
                this.shipment.onBehalfShipper.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.onBehalfShipperId)
            }
            if (this.shipment.onBehalfForwarder && this.shipment.onBehalfForwarderId) {
                this.shipment.onBehalfForwarder.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.onBehalfForwarderId)
            }
            if (this.shipment.onBehalfConsignee && this.shipment.onBehalfConsigneeId) {
                this.shipment.onBehalfConsignee.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.onBehalfConsigneeId)
            }
            if (this.shipment.onBehalfBuyer && this.shipment.onBehalfBuyerId) {
                this.shipment.onBehalfBuyer.organisationAddresses = await this.$API.listOrganisationAddresses(this.shipment.onBehalfBuyerId)
            }
        },
        async closeShipmentProfileModal() {
            this.acceptLiability = false;
            this.shipmentProfile = {};
            this.$emit('closeModal')
        },
        async updateShipment() {
            this.$confirm(
                "Are you sure you want to save the changes made to the Shipment?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            ).then(async () => {
                this.savingShipment = true;
                await this.$API.updateShipment(this.shipment)

                for (let i = 0; i < this.relationships.length; i++) {
                    await this.$API.updateShipmentRelationship(this.relationships[i])
                }

                this.acceptLiability = false;
                this.shipmentProfile = {};
                this.savingShipment = false;
                this.$message({
                    type: "success",
                    message: "Successfully updated!",
                });
                this.$emit('closeModal')
            })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Save canceled",
                    });
                });

        },
        async getShipmentProfile() {
            this.loadingProfile = true
            this.shipmentProfile = await this.$API.getShipmentProfile(this.shipment.consigneeProfileId)
            await this.setParties()
            this.loadingProfile = false
        },
        async getPartyAddresses(party) {
            this.loadingAdresses = true
            if (party && party.organisationAddresses && party.organisationAddresses.length > 0) {
                this.shipmentPartyAddresses = party.organisationAddresses.map((x) => ({ ...x, description: this.concatenateAddress(x) }))
            }
            this.loadingAdresses = false
        },
        async removeCustomer(relationship) {
            this.$confirm(
                "Are you sure you want to remove this Customer from the Shipment? This action cannot be undone.",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            ).then(async () => {
                this.removingCustomer = true
                relationship.isActive = false
                relationship.isDeleted = true
                await this.$API.updateShipmentRelationship(relationship)
                this.relationships.splice(this.relationships.findIndex(rel => rel === relationship), 1)
                this.removingCustomer = false
                this.$message({
                    type: "success",
                    message: "Successfully updated!",
                });
            })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Customer removal canceled",
                    });
                });
        },
        partyExsist(party) {
            if (party && party.name) { return true } else { return false }
        },
        removeInvoiceParty(type) {
            switch (type) {
                case 'origin':
                    if (this.shipment.originCharge !== 'forwarder') { this.shipment.originInvoiceTo = null }
                    break;
                case 'freight':
                    if (this.shipment.freightCharge !== 'forwarder') { this.shipment.freightInvoiceTo = null }
                    break;
                case 'destination':
                    if (this.shipment.destinationCharge !== 'forwarder') { this.shipment.destinationInvoiceTo = null }
                    break;
            }
        },
        getStatusColor(status) {
            switch (status) {
                case 'Draft':
                    return 'blue'
                case 'Approved':
                    return 'green'
                case 'Inactive':
                    return 'red'
            }
        },
        concatenateAddress(address) {
            if (address) {
                let result = [
                    this.constructAddressLine(1, address),
                    this.constructAddressLine(2, address),
                    this.constructAddressLine(3, address)
                ];
                return result.filter(Boolean).join(", ");
            } else {
                return
            }
        },
        constructAddressLine(addressLine, address) {
            switch (addressLine) {
                case 1:
                    return [address.addressLine1, address.addressLine2].filter(Boolean).join(', ')
                case 2:
                    return [address.addressLine3, address.addressLine4].filter(Boolean).join(', ')
                case 3:
                    return [address.town, address.subdivision, address.country, address.postalCode].filter(Boolean).join(', ')
            }
        },
        closePartyModal() {
            this.partyModal = false
            this.partyType = null
            this.partyAddress = null
            this.partyAddresses = []
            this.searchOrganisation = null
        },
        openCustomerModal(relationship) {
            // console.log(relationship);
            this.customerModal = true
            this.changeRelationshipId = relationship?.id ?? null
        },
        async setCustomer(customer) {
            if(this.relationships.length > 0){
                for (let i = 0; i < this.relationships.length; i++) {
                    if (this.relationships[i].id === this.changeRelationshipId) {
                        let obj = {
                            id: customer.relatedOrganisation.id,
                            logo: customer.relatedOrganisation.logo ? customer.relatedOrganisation.logo : null,
                            name: customer.relatedOrganisation.name
                        }
                        this.relationships[i].customer = obj
                        this.relationships[i].customerId = customer.relatedOrganisation.id
                        this.relationships[i].organisationRelationshipId = customer.id
                        this.relationships[i].organisationRelationship = customer
                    }
                }
            } else {
                this.newRelationship.customer = {
                    id: customer.relatedOrganisation.id,
                    logo: customer.relatedOrganisation.logo ? customer.relatedOrganisation.logo : null,
                    name: customer.relatedOrganisation.name
                }
                this.newRelationship.customerId = customer.relatedOrganisation.id
                this.newRelationship.organisationRelationshipId = customer.id
                this.newRelationship.organisationRelationship = customer
                this.newRelationship.shipmentId = this.shipment.id
                this.newRelationship.invoiceStatus = "NOTREADY"

                let result = await this.$API.createShipmentRelationship(this.newRelationship)
                this.newRelationship.id = result.id
                this.relationships.push(this.newRelationship)
                this.newRelationship = {}
                this.$message({
                    type: "success",
                    message: "Customer successfully added!",
                });
            }

            this.changeRelationshipId = null
            this.customerModal = false
        },
        setParties() {
            for (let i = 0; i < this.chargeOptions.length; i++) {
                if (this.chargeOptions[i].value == 'bank') {
                    this.chargeOptions[i].friendlyName = this.shipment.bank ? this.shipment.bank.name : null
                } else if (this.chargeOptions[i].value == 'shipper') {
                    this.chargeOptions[i].friendlyName = this.shipment.shipper ? this.shipment.shipper.name : null
                } else if (this.chargeOptions[i].value == 'forwarder') {
                    this.chargeOptions[i].friendlyName = this.shipment.forwarder ? this.shipment.forwarder.name : null
                } else if (this.chargeOptions[i].value == 'consignee') {
                    this.chargeOptions[i].friendlyName = this.shipment.consignee ? this.shipment.consignee.name : null
                } else if (this.chargeOptions[i].value == 'buyer') {
                    this.chargeOptions[i].friendlyName = this.shipment.buyer ? this.shipment.buyer.name : null
                } else if (this.chargeOptions[i].value == 'firstNotify') {
                    this.chargeOptions[i].friendlyName = this.shipment.firstNotify ? this.shipment.firstNotify.name : null
                } else if (this.chargeOptions[i].value == 'secondNotify') {
                    this.chargeOptions[i].friendlyName = this.shipment.secondNotify ? this.shipment.secondNotify.name : null
                } else if (this.chargeOptions[i].value == 'courierParty') {
                    this.chargeOptions[i].friendlyName = this.shipment.courierParty ? this.shipment.courierParty.name : null
                } else if (this.chargeOptions[i].value == 'additionalCourierParty') {
                    this.chargeOptions[i].friendlyName = this.shipment.additionalCourierParty ? this.shipment.additionalCourierParty.name : null
                } else if (this.chargeOptions[i].value == 'onBehalfShipper') {
                    this.chargeOptions[i].friendlyName = this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name : null
                } else if (this.chargeOptions[i].value == 'onBehalfForwarder') {
                    this.chargeOptions[i].friendlyName = this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name : null
                } else if (this.chargeOptions[i].value == 'onBehalfConsignee') {
                    this.chargeOptions[i].friendlyName = this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name : null
                } else if (this.chargeOptions[i].value == 'onBehalfBuyer') {
                    this.chargeOptions[i].friendlyName = this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name : null
                } else if (this.chargeOptions[i].value == 'customer') {
                    this.chargeOptions[i].friendlyName = this.relationships[0]?.customer ? this.relationships[0]?.customer?.name : null
                }
            }


        },
        setParty(party) {
            switch (this.partyType) {
                case 'bank':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.bank = party
                    this.shipment.bankId = party.id
                    this.shipment.bankAddress = party.organisationAddresses[0]
                    this.shipment.bankAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'shipper':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.shipper = party
                    this.shipment.shipperId = party.id
                    this.shipment.shipperAddress = party.organisationAddresses[0]
                    this.shipment.shipperAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'forwarder':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.forwarder = party
                    this.shipment.forwarderId = party.id
                    this.shipment.forwarderAddress = party.organisationAddresses[0]
                    this.shipment.forwarderAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'consignee':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.consignee = party
                    this.shipment.consigneeId = party.id
                    this.shipment.consigneeAddress = party.organisationAddresses[0]
                    this.shipment.consigneeAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'buyer':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.buyer = party
                    this.shipment.buyerId = party.id
                    this.shipment.buyerAddress = party.organisationAddresses[0]
                    this.shipment.buyerAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'firstNotify':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.firstNotify = party
                    this.shipment.firstNotifyId = party.id
                    this.shipment.firstNotifyAddress = party.organisationAddresses[0]
                    this.shipment.firstNotifyAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'secondNotify':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.secondNotify = party
                    this.shipment.secondNotifyId = party.id
                    this.shipment.secondNotifyAddress = party.organisationAddresses[0]
                    this.shipment.secondNotifyAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'courierParty':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.courierParty = party
                    this.shipment.courierPartyId = party.id
                    this.shipment.courierPartyAddress = party.organisationAddresses[0]
                    this.shipment.courierPartyAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'additionalCourierParty':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.additionalCourierParty = party
                    this.shipment.additionalCourierPartyId = party.id
                    this.shipment.additionalCourierPartyAddress = party.organisationAddresses[0]
                    this.shipment.additionalCourierPartyAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'onBehalfShipper':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.onBehalfShipper = party
                    this.shipment.onBehalfShipperId = party.id
                    this.shipment.onBehalfShipperAddress = party.organisationAddresses[0]
                    this.shipment.onBehalfShipperAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'onBehalfForwarder':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.onBehalfForwarder = party
                    this.shipment.onBehalfForwarderId = party.id
                    this.shipment.onBehalfForwarderAddress = party.organisationAddresses[0]
                    this.shipment.onBehalfForwarderAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'onBehalfConsignee':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.onBehalfConsignee = party
                    this.shipment.onBehalfConsigneeId = party.id
                    this.shipment.onBehalfConsigneeAddress = party.organisationAddresses[0]
                    this.shipment.onBehalfConsigneeAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
                case 'onBehalfBuyer':
                    party.organisationAddresses[0].description = this.concatenateAddress(party.organisationAddresses[0])
                    this.shipment.onBehalfBuyer = party
                    this.shipment.onBehalfBuyerId = party.id
                    this.shipment.onBehalfBuyerAddress = party.organisationAddresses[0]
                    this.shipment.onBehalfBuyerAddressId = party.organisationAddresses[0].id
                    this.closePartyModal()
                    return;
            }

        },
        selectPartyAddress(address, partyType) {
            switch (partyType) {
                case 'bank':
                    this.shipment.bankAddressId = address.id
                    this.shipment.bankAddress = address
                    return;
                case 'shipper':
                    this.shipment.shipperAddressId = address.id
                    this.shipment.shipperAddress = address
                    return;
                case 'forwarder':
                    this.shipment.forwarderAddressId = address.id
                    this.shipment.forwarderAddress = address
                    return;
                case 'consignee':
                    this.shipment.consigneeAddressId = address.id
                    this.shipment.consigneeAddress = address
                    return;
                case 'buyer':
                    this.shipment.buyerAddressId = address.id
                    this.shipment.buyerAddress = address
                    return;
                case 'firstNotify':
                    this.shipment.firstNotifyAddressId = address.id
                    this.shipment.firstNotifyAddress = address
                    return;
                case 'secondNotify':
                    this.shipment.secondNotifyAddressId = address.id
                    this.shipment.secondNotifyAddress = address
                    return;
                case 'courierParty':
                    this.shipment.courierPartyAddressId = address.id
                    this.shipment.courierPartyAddress = address
                    return;
                case 'additionalCourierParty':
                    this.shipment.additionalCourierPartyAddressId = address.id
                    this.shipment.additionalCourierPartyAddress = address
                    return;
                case 'onBehalfShipper':
                    this.shipment.onBehalfShipperAddressId = address.id
                    this.shipment.onBehalfShipperAddress = address
                    return;
                case 'onBehalfForwarder':
                    this.shipment.onBehalfForwarderAddressId = address.id
                    this.shipment.onBehalfForwarderAddress = address
                    return;
                case 'onBehalfConsignee':
                    this.shipment.onBehalfConsigneeAddressId = address.id
                    this.shipment.onBehalfConsigneeAddress = address
                    return;
                case 'onBehalfBuyer':
                    this.shipment.onBehalfBuyerAddressId = address.id
                    this.shipment.onBehalfBuyerAddress = address
                    return;
            }
        },
        addPartyToShipment(party, partyType) {
            switch (partyType) {
                case 'bank':
                    this.shipment.bank = party
                    this.shipment.bankId = party.id
                    this.shipment.bankAddress = this.shipmentProfile.bankAddress
                    this.shipment.bankAddressId = this.shipmentProfile.bankAddressId
                    return;
                case 'shipper':
                    this.shipment.shipper = party
                    this.shipment.shipperId = party.id
                    this.shipment.shipperAddress = this.shipmentProfile.shipperAddress
                    this.shipment.shipperAddressId = this.shipmentProfile.shipperAddressId
                    return;
                case 'forwarder':
                    this.shipment.forwarder = party
                    this.shipment.forwarderId = party.id
                    this.shipment.forwarderAddress = this.shipmentProfile.forwarderAddress
                    this.shipment.forwarderAddressId = this.shipmentProfile.forwarderAddressId
                    return;
                case 'consignee':
                    this.shipment.consignee = party
                    this.shipment.consigneeId = party.id
                    this.shipment.consigneeAddress = this.shipmentProfile.consigneeAddress
                    this.shipment.consigneeAddressId = this.shipmentProfile.consigneeAddressId
                    return;
                case 'buyer':
                    this.shipment.buyer = party
                    this.shipment.buyerId = party.id
                    this.shipment.buyerAddress = this.shipmentProfile.buyerAddress
                    this.shipment.buyerAddressId = this.shipmentProfile.buyerAddressId
                    return;
                case 'firstNotify':
                    this.shipment.firstNotify = party
                    this.shipment.firstNotifyId = party.id
                    this.shipment.firstNotifyAddress = this.shipmentProfile.firstNotifyAddress
                    this.shipment.firstNotifyAddressId = this.shipmentProfile.firstNotifyAddressId
                    return;
                case 'secondNotify':
                    this.shipment.secondNotify = party
                    this.shipment.secondNotifyId = party.id
                    this.shipment.secondNotifyAddress = this.shipmentProfile.secondNotifyAddress
                    this.shipment.secondNotifyAddressId = this.shipmentProfile.secondNotifyAddressId
                    return;
                case 'courierParty':
                    this.shipment.courierParty = party
                    this.shipment.courierPartyId = party.id
                    this.shipment.courierPartyAddress = this.shipmentProfile.courierPartyAddress
                    this.shipment.courierPartyAddressId = this.shipmentProfile.courierPartyAddressId
                    return;
                case 'additionalCourierParty':
                    this.shipment.additionalCourierParty = party
                    this.shipment.additionalCourierPartyId = party.id
                    this.shipment.additionalCourierPartyAddress = this.shipmentProfile.additionalCourierPartyAddress
                    this.shipment.additionalCourierPartyAddressId = this.shipmentProfile.additionalCourierPartyAddressId
                    return;
                case 'onBehalfShipper':
                    this.shipment.onBehalfShipper = party
                    this.shipment.onBehalfShipperId = party.id
                    this.shipment.onBehalfShipperAddress = this.shipmentProfile.shipperAddress
                    this.shipment.onBehalfShipperAddressId = this.shipmentProfile.shipperAddressId
                    return;
                case 'onBehalfForwarder':
                    this.shipment.onBehalfForwarder = party
                    this.shipment.onBehalfForwarderId = party.id
                    this.shipment.onBehalfForwarderAddress = this.shipmentProfile.forwarderAddress
                    this.shipment.onBehalfForwarderAddressId = this.shipmentProfile.forwarderAddressId
                    return;
                case 'onBehalfConsignee':
                    this.shipment.onBehalfConsignee = party
                    this.shipment.onBehalfConsigneeId = party.id
                    this.shipment.onBehalfConsigneeAddress = this.shipmentProfile.consigneeAddress
                    this.shipment.onBehalfConsigneeAddressId = this.shipmentProfile.consigneeAddressId
                    return;
                case 'onBehalfBuyer':
                    this.shipment.onBehalfBuyer = party
                    this.shipment.onBehalfBuyerId = party.id
                    this.shipment.onBehalfBuyerAddress = this.shipmentProfile.buyerAddress
                    this.shipment.onBehalfBuyerAddressId = this.shipmentProfile.buyerAddressId
                    return;
            }
        },
        removePartyFromShipment(party, partyType) {
            this.$confirm(
                "Are you sure you want to remove this party from the Shipment?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            ).then(async () => {
                switch (partyType) {
                    case 'bank':
                        this.shipment.bank = null
                        this.shipment.bankId = null
                        this.shipment.bankAddress = null
                        this.shipment.bankAddressId = null
                        return;
                    case 'shipper':
                        this.shipment.shipper = null
                        this.shipment.shipperId = null
                        this.shipment.shipperAddress = null
                        this.shipment.shipperAddressId = null
                        this.shipment.onBehalfShipper = null
                        this.shipment.onBehalfShipperId = null
                        this.shipment.onBehalfShipperAddress = null
                        this.shipment.onBehalfShipperAddressId = null
                        return;
                    case 'forwarder':
                        this.shipment.forwarder = null
                        this.shipment.forwarderId = null
                        this.shipment.forwarderAddress = null
                        this.shipment.forwarderAddressId = null
                        this.shipment.onBehalfForwarder = null
                        this.shipment.onBehalfForwarderId = null
                        this.shipment.onBehalfForwarderAddress = null
                        this.shipment.onBehalfForwarderAddressId = null
                        return;
                    case 'consignee':
                        this.shipment.consignee = null
                        this.shipment.consigneeId = null
                        this.shipment.consigneeAddress = null
                        this.shipment.consigneeAddressId = null
                        this.shipment.onBehalfConsignee = null
                        this.shipment.onBehalfConsigneeId = null
                        this.shipment.onBehalfConsigneeAddress = null
                        this.shipment.onBehalfConsigneeAddressId = null
                        return;
                    case 'buyer':
                        this.shipment.buyer = null
                        this.shipment.buyerId = null
                        this.shipment.buyerAddress = null
                        this.shipment.buyerAddressId = null
                        this.shipment.onBehalfBuyer = null
                        this.shipment.onBehalfBuyerId = null
                        this.shipment.onBehalfBuyerAddress = null
                        this.shipment.onBehalfBuyerAddressId = null
                        return;
                    case 'firstNotify':
                        this.shipment.firstNotify = null
                        this.shipment.firstNotifyId = null
                        this.shipment.firstNotifyAddress = null
                        this.shipment.firstNotifyAddressId = null
                        return;
                    case 'secondNotify':
                        this.shipment.secondNotify = null
                        this.shipment.secondNotifyId = null
                        this.shipment.secondNotifyAddress = null
                        this.shipment.secondNotifyAddressId = null
                        return;
                    case 'courierParty':
                        this.shipment.courierParty = null
                        this.shipment.courierPartyId = null
                        this.shipment.courierPartyAddress = null
                        this.shipment.courierPartyAddressId = null
                        return;
                    case 'additionalCourierParty':
                        this.shipment.additionalCourierParty = null
                        this.shipment.additionalCourierPartyId = null
                        this.shipment.additionalCourierPartyAddress = null
                        this.shipment.additionalCourierPartyAddressId = null
                        return;
                    case 'onBehalfShipper':
                        this.shipment.onBehalfShipper = null
                        this.shipment.onBehalfShipperId = null
                        this.shipment.onBehalfShipperAddress = null
                        this.shipment.onBehalfShipperAddressId = null
                        return;
                    case 'onBehalfForwarder':
                        this.shipment.onBehalfForwarder = null
                        this.shipment.onBehalfForwarderId = null
                        this.shipment.onBehalfForwarderAddress = null
                        this.shipment.onBehalfForwarderAddressId = null
                        return;
                    case 'onBehalfConsignee':
                        this.shipment.onBehalfConsignee = null
                        this.shipment.onBehalfConsigneeId = null
                        this.shipment.onBehalfConsigneeAddress = null
                        this.shipment.onBehalfConsigneeAddressId = null
                        return;
                    case 'onBehalfBuyer':
                        this.shipment.onBehalfBuyer = null
                        this.shipment.onBehalfBuyerId = null
                        this.shipment.onBehalfBuyerAddress = null
                        this.shipment.onBehalfBuyerAddressId = null
                        return;
                }
                this.$message({
                    type: "success",
                    message: "Party Removed!",
                });
            })
        }
    }
}
</script>

<style scoped>
.scroll {
    overflow-y: scroll;
    max-height: 28vh;
}
</style>
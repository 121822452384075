<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="3000" absolute bottom color="success" center text style="z-index: 99999;">
      Copied to clipboard!
    </v-snackbar>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card flat id="relevant-info" outlined>
          <v-toolbar flat color="transparent">
            <div>
              <v-toolbar-title class="mb-1">
                Shipment Details
              </v-toolbar-title>
              <code small
                v-if="shipment.consigneeProfile"> <b>Shipment Profile Reference:</b> {{ shipment.consigneeProfile.systemReference }}</code>
              <code small class="ml-5"
                v-if="shipment.consigneeProfile.consigneeProfilePorts && shipment.consigneeProfile.consigneeProfilePorts.length > 0"><b>Documentation POD:</b> {{ shipment.consigneeProfile.consigneeProfilePorts[0].name }}</code>
            </div>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn text plain v-on="on" icon @click="shipmentProfileDialog = true"><v-icon>edit</v-icon></v-btn>
              </template>
              <span>Edit Shipment Profile Details</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col class="pb-0">
                <v-text-field dense outlined persistent-placeholder disabled label="Inco Term"
                  v-model="shipment.shipmentIncoTerm"></v-text-field>
                <v-text-field dense outlined persistent-placeholder disabled label="Origin Charge" v-model="originCharge">
                </v-text-field>
                <v-text-field dense outlined persistent-placeholder disabled label="Freight Charge"
                  v-model="freightCharge">
                </v-text-field>
                <v-text-field v-if="shipment.destinationCharge" dense outlined persistent-placeholder disabled
                  label="Destination Charge" v-model="destinationCharge">
                </v-text-field>
              </v-col>
              <v-col class="pb-0">
                <v-text-field dense outlined persistent-placeholder disabled label="Contract Owner"
                  v-model="incoTermContractOwner">
                </v-text-field>
                <v-text-field dense outlined persistent-placeholder disabled label="Forwarder Invoice Origin Charge To"
                  v-model="originInvoiceTo">
                </v-text-field>
                <v-text-field dense outlined persistent-placeholder disabled label="Forwarder Invoice Freight Charge To"
                  v-model="freightInvoiceTo">
                </v-text-field>
                <v-text-field dense outlined persistent-placeholder disabled
                  label="Forwarder Invoice Destination Charge To" v-model="destinationInvoiceTo">
                </v-text-field>

                <!-- <v-text-field
                  v-if="shipment.consigneeProfile.consigneeProfilePorts && shipment.consigneeProfile.consigneeProfilePorts.length > 0"
                  dense outlined persistent-placeholder disabled label="Documentation POD"
                  v-model="shipment.consigneeProfile.consigneeProfilePorts[0].name">
              </v-text-field>           -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="my-0 py-0">
            <v-subheader class="mt-0 pt-0 primaryText--text" style="font-size: 16px">
              <v-icon left color="grey">groups</v-icon> Assigned Parties
              <v-spacer></v-spacer>
              <v-checkbox disabled v-model="shipment.letterOfCredit" label="Letter of Credit Required"></v-checkbox>
            </v-subheader>
            <v-list :loading="loading" dense subheader :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'">

              <!-- Bank -->
              <v-card tile outlined v-if="shipment.bank">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center">
                    <v-icon class="mt-0 pt-0">account_balance</v-icon>
                    <br />
                    <span style="font-size: 10px; font-weight: bold">BANK</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0"><span v-if="shipment.bank">{{ shipment.bank.name }}</span>
                      <span v-else>-</span>
                    </v-card-title>
                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="shipment.bankAddressId ||
                        shipment.bankAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.bankAddressDescription">{{
                          shipment.bankAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.bankAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Shipper -->
              <v-card tile outlined v-if="shipment.shipper">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon class="mt-0 pt-0">directions_boat_filled</v-icon>
                    <br />
                    <span style="font-size: 10px; font-weight: bold">SHIPPER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !shipment.onBehalfShipper ? '0px' : '6px',
                    }" class="my-0 pt-0">{{ shipment.shipper.name }}
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="shipment.shipper && shipment.onBehalfShipper">
                      On Behalf of {{ shipment.onBehalfShipper.name }}</v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="shipment.shipper && !shipment.onBehalfShipper">
                      <p style="font-size: 12px; color: grey" v-if="shipment.shipperAddressId ||
                        shipment.shipperAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.shipperAddressDescription">{{
                          shipment.shipperAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.shipperAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                    <v-card-text class="my-0 mt-0 pt-0"
                      v-else-if="shipment.onBehalfShipper && shipment.onBehalfShipperAddress">
                      <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(shipment.onBehalfShipperAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>

              <!-- forwarder -->
              <v-card tile outlined v-if="shipment.forwarder">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>fast_forward</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">FORWARDER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !shipment.onBehalfForwarder
                        ? '0px'
                        : '5px',
                    }" class="my-0 pt-0">{{ shipment.forwarder.name }}
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="shipment.forwarder && shipment.onBehalfForwarder">
                      On Behalf of {{ shipment.onBehalfForwarder.name }}</v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="shipment.forwarder && !shipment.onBehalfForwarder">
                      <p style="font-size: 12px; color: grey" v-if="shipment.forwarderAddressId ||
                        shipment.forwarderAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.forwarderAddressDescription">{{
                          shipment.forwarderAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.forwarderAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                    <v-card-text class="my-0 mt-0 pt-0"
                      v-else-if="shipment.onBehalfForwarder && shipment.onBehalfForwarderAddress">
                      <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(shipment.onBehalfForwarderAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>


              <!-- consignee -->
              <v-card tile outlined v-if="shipment.consignee">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>call_received</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">CONSIGNEE</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !shipment.onBehalfConsignee && !(shipment.letterOfCredit && shipment.bank)
                        ? '0px'
                        : '5px',
                    }" class="my-0 pt-0">{{ shipment.consignee.name }}
                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="shipment.consignee && shipment.onBehalfConsignee">
                      On Behalf of {{ shipment.onBehalfConsignee.name }}</v-card-subtitle>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="shipment.letterOfCredit && shipment.bank">
                      To the Order of {{ shipment.bank.name }}</v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="shipment.consignee && !shipment.onBehalfConsignee">
                      <p style="font-size: 12px; color: grey" v-if="shipment.consigneeAddressId ||
                        shipment.consigneeAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.consigneeAddressDescription">{{
                          shipment.consigneeAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.consigneeAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                    <v-card-text class="my-0 mt-0 pt-0"
                      v-else-if="shipment.onBehalfConsignee && shipment.onBehalfConsigneeAddress">
                      <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(shipment.onBehalfConsigneeAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>


              <!-- Buyer -->
              <v-card tile outlined v-if="shipment.buyer">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>shopping_cart</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">BUYER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': !shipment.onBehalfBuyer
                        ? '0px'
                        : '5px',
                    }" class="my-0 pt-0">{{ shipment.buyer.name }}

                    </v-card-title>
                    <v-card-subtitle style="font-size: 14px" class="mb-0 pb-0"
                      v-if="shipment.buyer && shipment.onBehalfBuyer">
                      On Behalf of {{ shipment.onBehalfBuyer.name }}</v-card-subtitle>
                    <v-card-text class="my-0 mt-0 pt-0" v-if="shipment.buyer && !shipment.onBehalfBuyer">
                      <p style="font-size: 12px; color: grey" v-if="shipment.buyerAddressId ||
                        shipment.buyerAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.buyerAddressDescription">{{
                          shipment.buyerAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.buyerAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                    <v-card-text class="my-0 mt-0 pt-0"
                      v-else-if="shipment.onBehalfBuyer && shipment.onBehalfBuyerAddress">
                      <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span>{{
                          concatenateAddress(shipment.onBehalfBuyerAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>


              <!-- First Notify -->
              <v-card tile outlined v-if="shipment.firstNotify">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>notifications</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">1st NOTIFY</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ shipment.firstNotify.name }}
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="shipment.firstNotifyAddressId ||
                        shipment.firstNotifyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.firstNotifyAddressDescription">{{
                          shipment.firstNotifyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.firstNotifyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Second Notify -->
              <v-card tile outlined v-if="shipment.secondNotify">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>notifications</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">2nd NOTIFY</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ shipment.secondNotify.name }}
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="shipment.secondNotifyAddressId ||
                        shipment.secondNotifyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.secondNotifyAddressDescription">{{
                          shipment.secondNotifyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.secondNotifyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>


              <!-- Courier -->
              <v-card tile outlined v-if="shipment.courierParty">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>local_post_office</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">COURIER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ shipment.courierParty.name }}
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="shipment.courierPartyAddressId ||
                        shipment.courierPartyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.courierPartyAddressDescription">{{
                          shipment.courierPartyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.courierPartyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Additional Courier -->
              <v-card tile outlined v-if="shipment.additionalCourierParty">
                <v-row align="center" class="mt-1">
                  <v-col cols="12" sm="2" class="text-center my-0 py-0">
                    <v-icon>local_post_office</v-icon> <br />
                    <span style="font-size: 10px; font-weight: bold">ADD. COURIER</span>
                  </v-col>
                  <v-col cols="12" sm="10" class="my-0 py-0">
                    <v-card-title :style="{
                      'font-size': '14px',
                      'padding-bottom': '0px'
                    }" class="my-0 pt-0">{{ shipment.additionalCourierParty.name }}
                    </v-card-title>

                    <v-card-text class="my-0 mt-0 pt-0">
                      <p style="font-size: 12px; color: grey" v-if="shipment.additionalCourierPartyAddressId ||
                        shipment.additionalCourierPartyAddressDescription
                        " class="text-wrap my-0 py-0">
                        <v-icon class="mr-1" small>location_on</v-icon>
                        <span v-if="shipment.additionalCourierPartyAddressDescription">{{
                          shipment.additionalCourierPartyAddressDescription
                        }}</span>
                        <span v-else>{{
                          concatenateAddress(shipment.additionalCourierPartyAddress)
                        }}</span>
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card outlined id="subscriber-card">
          <v-card-title class="mb-0 pb-0"><v-icon left color="grey">notifications</v-icon>
            Subscribers
          </v-card-title>
          <v-card-text class="mt-0 pt-0">
            <v-progress-linear v-if="loadingSubscribers" indeterminate color="primary"></v-progress-linear>
            <v-row>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-subheader class="mt-0 pt-0">Teams<v-btn icon @click="editTeams()"
                    color="primary"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-list dense>
                  <v-list-item v-for="team in teams" :key="team.id" :style="{ 'border-left': `5px solid ${team.colour}` }"
                    class="my-1">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ team.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="allTeams.length > 0">
                        {{ team.userTeams.length }} Member(s)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="teams.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No teams listed</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-subheader class="mt-0 pt-0">Users<v-btn icon @click="editUsers()"
                    color="primary"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-list dense>
                  <v-list-item v-for="user in subscribedUsers" :key="user.id" class="my-1">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ user.user.firstname }} {{
                          user.user.surname
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="user.user.emailAddress">
                        <v-icon class="mr-1" small>email</v-icon> {{
                          user.user.emailAddress
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="subscribedUsers.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No users listed</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>


            </v-row>

          </v-card-text>
        </v-card>
        <v-card outlined class="mt-2" id="distribution-list">
          <v-card-title style="font-size: 16px">
            <v-icon left color="grey">email</v-icon> Distribution List
            <v-spacer></v-spacer>
            <v-tooltip top v-if="distributionList.length > 0">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="copyDistributionList()" color="blue"><v-icon>content_copy</v-icon></v-btn>

              </template>
              <span>Copy All</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-list dense :key="emailKey">
              <v-list-item v-if="distributionList.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey">No email addresses</span>
                </v-list-item-content></v-list-item>

              <v-list-item v-for="email in distributionList" :key="email.index">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">{{
                    email.email
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <!-- <v-card flat :loading="loadingComments">
          <v-card-text class="my-0 py-0">
            <v-subheader class="mt-0 pt-0 primaryText--text" style="font-size: 16px">
              <v-icon left color="grey">speaker_notes</v-icon> Document Notes
            </v-subheader>
            <v-list dense subheader style="height: 55vh; overflow-y: auto"
              :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'px-3'">
              <div v-if="documentComments.length > 0">
                <v-card outlined v-for="document in documentComments" :key="document.id">
                  <v-row align="center" class="mt-1">
                    <v-col cols="12" sm="10" class="my-0 py-0">
                      <v-card-title :style="{
                        'font-size': '15px',
                        'padding-bottom': '0px'
                      }" class="my-0 pt-0">{{ document.locationDocument.name }}
                      </v-card-title>
                      <v-card-text class="my-0 mt-0 pt-0">
                        <v-card-subtitle :style="{ 'font-size': '13px', 'padding': '0px' }"
                          v-if="document.comment"><v-icon small>text_snippet</v-icon> Comment:
                          <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                            <span>{{ document.comment }}</span>
                          </p>
                        </v-card-subtitle>
                        <v-card-subtitle :style="{ 'font-size': '13px', 'padding': '0px' }"
                          v-if="document.specialInstruction"><v-icon small>checklist</v-icon> Special Instruction:
                          <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                            <span>{{ document.specialInstruction }}</span>
                          </p>
                        </v-card-subtitle>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div v-else>
                <v-card flat>
                  <v-card-text class="my-0 mt-0 pt-0">
                    <p style="font-size: 12px; color: grey" class="text-wrap my-0 py-0">
                      <span> No special instructions or comments made on documents. </span>
                    </p>
                  </v-card-text>
                </v-card>
              </div>

            </v-list>
          </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>

    <v-dialog v-model="teamModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title><v-icon class="mr-2">groups</v-icon>Assigned Teams</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="saveTeams" :loading="savingTeams"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="teamModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-for="team in allTeams" :key="team.id" :style="{ 'border-left': `5px solid ${team.colour}` }"
              class="my-1">
              <v-list-item-content>
                <v-list-item-title>
                  {{ team.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox color="primary" v-model="selectedTeams" :value="team.id"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2">person</v-icon>
            Subscribed Users
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="saveUsers" :loading="savingUsers"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="userModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
              <el-input suffix-icon="el-icon-search" v-model="userSearch" clearable placeholder="Search">
              </el-input>
            </v-col>
          </v-row>
          <v-data-table v-model="selectedUsers" show-select id="user-table" dense :items="users" :loading="loadingUsers"
            :search="userSearch" :headers="headers" style="cursor: pointer;">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit Shipment Profile Details -->
    <shipmentProfileModal :shipment="shipment" :shipmentProfileDialog="shipmentProfileDialog"
      :relationships="relationships" @closeModal="closeShipmentProfileModal" />

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          Welcome to Shipment Profile!
        </v-card-title>
        <v-card-text>
          <span>
            <p>This section offers a comprehensive overview of the involved companies, their designated roles, and
              addresses within the shipment.
              It outlines the assigned INCO Term and payment parties for distinct shipment stages.</p>
            <p>The section highlights subscribed teams and users from pertinent companies, receiving system notifications
              for
              updates on the shipment. A distribution list is presented, indicating users who will receive email
              notifications
              with direct links to the system for quick access to any shipment-related updates. This fosters enhanced
              collaboration
              and ensures stakeholders remain well-informed throughout the entirety of the shipment process.
            </p>

          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Help Guide/ Tutorial -->
    <v-btn id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh"
      style="position: absolute; z-index: 5; right: 1vw; top: 1vh">
      <v-icon>help</v-icon>
    </v-btn>
  </div>
</template>
<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from './Steps/consigneeProfileSteps'
import shipmentProfileModal from '../SystemComponents/ShipmentProfileModal.vue'

export default {
  props: ['shipment', 'userScope'],
  components: {
    shipmentProfileModal
  },
  data: () => ({
    steps: null,
    allTeams: [],
    distributionList: [],
    documentComments: [],
    emailKey: 1000,
    headers: [{
      text: 'Name',
      value: 'user.firstname',
      align: 'center',
    },
    {
      text: 'Surname',
      value: 'user.surname',
      align: 'center',
    },
    {
      text: 'Email',
      value: "user.emailAddress",
      align: 'center',
    },
    {
      text: 'Position',
      value: "position",
      align: 'center',
    },
    {
      text: 'Office',
      value: "organisationAddress.alias",
      align: 'center',
    }
    ],
    loading: false,
    loadingComments: false,
    loadingSubscribers: false,
    loadingUsers: true,
    notificationSubscribers: {},
    item: {},
    savingUsers: false,
    savingTeams: false,
    selectedTeams: [],
    selectedUsers: [],
    snackbar: false,
    userModal: false,
    users: [],
    userSearch: null,
    teamModal: false,
    notifyOneName: null,
    notifySecName: null,
    infoStart: false,
    customerName: null,
    shipmentProfileDialog: false,
    originCharge: '',
    originInvoiceTo: '',
    freightCharge: '',
    freightInvoiceTo: '',
    destinationCharge: '',
    destinationInvoiceTo: '',
    savingShipment: false,
    relationships: [],
    contractOwner:'',
  }),
  watch: {
    'shipment.id': {
      immediate: true,
      handler(val) {
        if (val) {
          this.getNotificationSubscribers(val)
          this.originCharge = this.setChargeDescription('origin')
          this.originInvoiceTo = this.setChargeDescription('originInvoice')
          this.destinationCharge = this.setChargeDescription('destination')
          this.destinationInvoiceTo = this.setChargeDescription('destinationInvoice')
          this.freightCharge = this.setChargeDescription('freight')
          this.freightInvoiceTo = this.setChargeDescription('freightInvoice')
          this.incoTermContractOwner = this.setChargeDescription('contractOwner')
        }
      }
    },
    'shipment.consigneeProfileId': {
      immediate: true,
      handler(val) {
        if (val) {
          this.load()
        }
      }
    }
  },
  computed: {
    teams() {
      let filteredTeams = this.notificationSubscribers && this.notificationSubscribers.teams ? this.notificationSubscribers.teams.filter(x => x.teamId).map(x => x.teamId) : []
      let result = this.allTeams.filter(x => filteredTeams.includes(x.id))
      return result
    },
    subscribedUsers() {
      let filteredUsers = this.notificationSubscribers && this.notificationSubscribers.users ? this.notificationSubscribers.users.filter(x => x.organisationUserId).map(x => x.organisationUserId) : []
      let result = this.users.filter(x => filteredUsers.includes(x.id))
      return result
    }
  },
  async created() {
    this.getTeams()
    this.getOrganisationUsers()
    this.shipmentCustomer()
    await this.getSteps();
  },
  async mounted() {
    this.driver = new Driver({
      animate: false
    })

  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ShipmentSummary/ConsigneeProfileView/Steps',
        });
        this.steps = steps.steps
        },
    closeShipmentProfileModal() {
      this.originCharge = this.setChargeDescription('origin')
      this.freightCharge = this.setChargeDescription('freight')
      this.destinationCharge = this.setChargeDescription('destination')
      this.shipmentProfileDialog = false;
      this.shipmentCustomer()
    },
    constructAddressLine(addressLine, address) {
      // switch (addressLine) {
      //     case 1:
      //         return address.addressLine1
      //     case 2:
      //         return address.town ?? address.addressLine2
      //     case 3:
      //         return [address.subdivision ?? address.addressLine3, address.country ?? address.addressLine4, address.postalCode].filter(Boolean).join(', ')
      // }
      if (address) {
        switch (addressLine) {
          case 1:
            return [address.addressLine1, address.addressLine2].filter(Boolean).join(', ')
          case 2:
            return [address.addressLine3, address.addressLine4].filter(Boolean).join(', ')
          case 3:
            return [address.town, address.subdivision, address.country, address.postalCode].filter(Boolean).join(', ')
        }
      } else {
        return null
      }

    },
    async shipmentCustomer() {
      let shipmentId = this.shipment.id
      this.relationships = await this.$API.getShipmentRelationships(shipmentId)
      if (this.shipment.freightCharge === 'customer') {
        this.customerName = this.relationships[0]?.customer.name
      }
    },
    concatenateAddress(address) {

      let result = [
        this.constructAddressLine(1, address),
        this.constructAddressLine(2, address),
        this.constructAddressLine(3, address)
      ];
      return result.filter(Boolean).join(", ");
    },
    copyDistributionList() {
      let emails = this.distributionList.map(x => x.email).join(';')
      navigator.clipboard.writeText(emails);
      this.snackbar = true;
    },
    editTeams() {
      this.selectedTeams = this.notificationSubscribers.teams.filter(x => x.teamId).map(team => team.teamId)
      this.teamModal = true
    },
    editUsers() {
      this.selectedUsers = this.notificationSubscribers.users.filter(x => x.organisationUserId).map(x => x.organisationUser)
      this.userModal = true
    },
    async getNotificationSubscribers(id) {
      this.loadingSubscribers = true
      this.notificationSubscribers = await this.$API.getShipmentSubscribers(id)
      this.loadingSubscribers = false
    },
    async getOrganisationUsers() {
      this.loadingUsers = true
      this.users = await this.$API.getOrganisationUsers()
      this.loadingUsers = false
    },
    async getTeams() {
      this.allTeams = await this.$API.getTeams();
    },
    async load() {
      this.loading = true;
      this.distributionList = await this.$API.getConsigneeProfileDistributionList(this.shipment.consigneeProfileId)

      // this.item = await this.$API.getConsigneeProfile(this.shipment.consigneeProfileId);
      this.shipment.modalType = "Edit";
      // this.shipment.consigneeProfileShippingContracts =
      //   await this.$API.getConsigneeProfileContracts(this.shipment.consigneeProfileId);
      // this.shipment.consigneeProfileDocuments =
      //   await this.$API.getConsigneeProfileDocuments(this.shipment.consigneeProfileId);
      // await this.loadDocumentComments()
      this.loading = false;
    },
    async loadDocumentComments() {
      this.loadingComments = true
      this.documentComments = await this.$API.getConsigneeProfileDocComments(this.shipment.consigneeProfileId)
      this.loadingComments = false
    },
    async saveTeams() {
      this.savingTeams = true
      if (this.notificationSubscribers && this.notificationSubscribers.teams) {
        let create = this.selectedTeams.filter(
          (x) => !this.notificationSubscribers.teams.map((y) => y.teamId).includes(x)
        );
        let remove = this.notificationSubscribers.teams.filter(
          (x) => !this.selectedTeams.includes(x.teamId)
        );
        if (create.length > 0) {
          await this.$API.createShipmentSubscribers({
            batch: create.map((x) => ({
              teamId: x,
              shipmentId: this.shipment.id,
            })),
          });
        } else {
          for (let j = 0; j < remove.length; j++) {
            let relationshipTeam = this.notificationSubscribers.teams.find(
              (x) => x.teamId == remove[j].teamId
            );
            await this.$API.updateShipmentSubscribers({
              id: relationshipTeam.id,
              isActive: false,
              isDeleted: true,
            });
          }
        }
      }
      this.getNotificationSubscribers(this.shipment.id)
      this.savingTeams = false
      this.teamModal = false
    },
    async saveUsers() {
      this.savingUsers = true
      if (this.notificationSubscribers && this.notificationSubscribers.users) {
        let create = this.selectedUsers.filter(
          (x) => !this.notificationSubscribers.users.filter((y) => y.organisationUserId).map((y) => y.organisationUserId).includes(x.id)
        );
        let remove = this.notificationSubscribers.users.filter(
          (x) => !this.selectedTeams.map(y => y.id).includes(x.organisationUserId)
        );
        if (create.length > 0) {
          await this.$API.createShipmentSubscribers({
            batch: create.map((x) => ({
              organisationUserId: x.id,
              shipmentId: this.shipment.id,
            })),
          });
        } else {
          for (let j = 0; j < remove.length; j++) {
            let relationshipTeam = this.notificationSubscribers.users.find(
              (x) => x.organisationUserId == remove[j].organisationUserId
            );
            await this.$API.updateShipmentSubscribers({
              id: relationshipTeam.id,
              isActive: false,
              isDeleted: true,
            });
          }
        }
      }
      this.getNotificationSubscribers(this.shipment.id)
      this.savingUsers = false
      this.userModal = false
    },
    setChargeDescription(chargeType) {
      let description
      if (chargeType == 'origin') {
        description = this.shipment.originCharge === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.originCharge === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.originCharge === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.originCharge === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.originCharge === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.originCharge === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.originCharge === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.originCharge === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.originCharge === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.originCharge === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.originCharge === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      } else if (chargeType == 'freight') {
        description = this.shipment.freightCharge === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.freightCharge === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.freightCharge === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.freightCharge === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.freightCharge === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.freightCharge === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.freightCharge === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.freightCharge === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.freightCharge === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.freightCharge === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.freightCharge === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      } else if (chargeType == 'destination') {
        description = this.shipment.destinationCharge === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.destinationCharge === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.destinationCharge === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.destinationCharge === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.destinationCharge === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.destinationCharge === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.destinationCharge === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.destinationCharge === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.destinationCharge === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.destinationCharge === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.destinationCharge === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      } else if (chargeType == 'originInvoice') {
        description = this.shipment.originInvoiceTo === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.originInvoiceTo === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.originInvoiceTo === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.originInvoiceTo === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.originInvoiceTo === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.originInvoiceTo === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.originInvoiceTo === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.originInvoiceTo === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.originInvoiceTo === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.originInvoiceTo === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.originInvoiceTo === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      } else if (chargeType == 'freightInvoice') {
        description = this.shipment.freightInvoiceTo === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.freightInvoiceTo === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.freightInvoiceTo === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.freightInvoiceTo === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.freightInvoiceTo === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.freightInvoiceTo === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.freightInvoiceTo === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.freightInvoiceTo === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.freightInvoiceTo === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.freightInvoiceTo === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.freightInvoiceTo === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      } else if (chargeType == 'destinationInvoice') {
        description = this.shipment.destinationInvoiceTo === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.destinationInvoiceTo === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.destinationInvoiceTo === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.destinationInvoiceTo === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.destinationInvoiceTo === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.destinationInvoiceTo === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.destinationInvoiceTo === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.destinationInvoiceTo === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.destinationInvoiceTo === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.destinationInvoiceTo === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.destinationInvoiceTo === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      } else if (chargeType === 'contractOwner'){
        description = this.shipment.incoTermContractOwner === 'buyer' && this.shipment.buyer ? this.shipment.buyer.name :
          this.shipment.incoTermContractOwner === 'shipper' && this.shipment.shipper ? this.shipment.shipper.name :
            this.shipment.incoTermContractOwner === 'forwarder' && this.shipment.forwarder ? this.shipment.forwarder.name :
              this.shipment.incoTermContractOwner === 'consignee' && this.shipment.consignee ? this.shipment.consignee.name :
                this.shipment.incoTermContractOwner === 'customer' && this.shipment.customer ? this.shipment.customer.name :
                  this.shipment.incoTermContractOwner === 'firstNotify' && this.shipment.firstNotify ? this.shipment.firstNotify.name :
                    this.shipment.incoTermContractOwner === 'secondNotify' && this.shipment.secondNotify ? this.shipment.secondNotify.name :
                      this.shipment.incoTermContractOwner === 'onBehalfBuyer' && this.shipment.onBehalfBuyer ? this.shipment.onBehalfBuyer.name :
                        this.shipment.incoTermContractOwner === 'onBehalfShipper' && this.shipment.onBehalfShipper ? this.shipment.onBehalfShipper.name :
                          this.shipment.incoTermContractOwner === 'onBehalfForwarder' && this.shipment.onBehalfForwarder ? this.shipment.onBehalfForwarder.name :
                            this.shipment.incoTermContractOwner === 'onBehalfConsignee' && this.shipment.onBehalfConsignee ? this.shipment.onBehalfConsignee.name :
                              ''
      }
      return description
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.steps)
      this.driver.start()
    },

  }
}
</script>

<style>
.row-gap {
  margin-top: 3px;
  margin-bottom: 0px;
}
</style>